import React, { useEffect } from "react"
import { Link } from "gatsby"
import {
  GridBox,
  GridContainer,
  GridSpacer,
  Section,
  Standalone,
  Text,
  Title,
} from "@thedgbrt/logology-ui-components"
import SEO from "../../components/seo"
import { trackSegmentEvent } from "../../helpers/segment"
import { trackAmplitude } from "../../helpers/amplitude"

const VisionPage = () => {
  useEffect(() => {
    trackSegmentEvent("track", "View vision page")
    trackAmplitude("View vision page")
  }, [])

  return (
    <>
      <SEO title="Our vision" />
      <GridBox type={5} colSpans={[[2, 5], [2, 5], [2, 7]]} withBgContainer>
        <Title standalone>Our vision</Title>
      </GridBox>

      <GridBox type={5} colSpans={[[3, 6], [3, 6], [3, 8]]} withBgContainer>
        <Title level={3} standalone>
          A new kind of design service
        </Title>
      </GridBox>

      <GridBox type={5} colSpans={[[2, 7], [2, 7], [3, 9]]} withBgContainer>
        <Text standalone italic>
          Our product bridges the gap between working directly with a designer,
          who gets to the heart of what the meaning of the logo should be, and
          online generated logos. We figured out how to achieve a similar level
          of quality that you would get from a one-on-one collaboration with a
          skilled designer, only automatically and for a tenth of the price.
        </Text>
      </GridBox>

      <GridSpacer desktop={77} mobile={38} tablet={38} type={5} />

      <GridContainer
        type={5}
        rowsDesktop={["auto"]}
        rowsMobile={["auto"]}
        rowsTablet={["auto"]}
      >
        <GridBox
          type={5}
          colSpans={[[3, 9], [3, 9], [4, 12]]}
          withBorderTopBottom
        >
          <Section title="Who we are" image="/images/photo-drawing.jpg">
            <Text italic standalone>
              We’re the result of a partnership between an experienced logo
              designer and a product builder:
              <br />
              <strong>
                <a
                  href="https://www.instagram.com/luciebaratte/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Lucie
                </a>
              </strong>{" "}
              is a graphic designer who specializes in Typography and Logo
              Design. She’s led numerous Logo and branding projects for startups
              as well as established brands. <br />
              <strong>
                <a
                  href="https://twitter.com/dagorenouf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Dagobert
                </a>
              </strong>{" "}
              is a product designer and software developer, for years he’s
              helped startups figure out what their best product is, and how to
              skillfully build it.
            </Text>
          </Section>
        </GridBox>
      </GridContainer>

      <GridSpacer desktop={77} mobile={38} tablet={38} type={5} />

      <GridBox type={5} colSpans={[[2, 6], [2, 6], [3, 7]]} withBgContainer>
        <Title level={3} standalone>
          Why we are doing this
        </Title>
      </GridBox>

      <GridContainer
        type={5}
        rowsDesktop={["auto"]}
        rowsMobile={["auto"]}
        rowsTablet={["auto"]}
      >
        <GridBox type={5} colSpans={[[3, 8], [3, 8], [4, 9]]}>
          <Standalone>
            <p>
              <em>
                We believe that a founders' number one priority should be to
                build their product and get their first customers. When you’re
                just getting started, you shouldn’t spend too much time or money
                wrestling with a designer to get a logo. It hinders your
                progress right now, and the result is likely to lose relevance
                as the vision for the product matures.
              </em>
            </p>
            <p>
              <em>
                Still, the difference between starting up with a great logo, as
                opposed to merely a decent one, can be the thing that makes or
                break a first impression to clients and investors.
              </em>
            </p>
            <p>
              <em>
                We believe we’ve found a way for founders to stay focused on
                their success, and still get a great logo, regardless of the
                stage of development their startup is at. Logology will enable
                thousands of founders to gain access to the logo that they dream
                of while supporting them in getting their startup to survive.
              </em>
            </p>
            <p>
              <em>If you’re a founder and resonate with our vision, </em>
              <Link to="/questionnaire/">get started here →</Link>.
            </p>
          </Standalone>
        </GridBox>
      </GridContainer>
    </>
  )
}

export default VisionPage
